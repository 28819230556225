import React, {Component} from 'react';
import {observer} from 'mobx-react';

interface IntroSectionProps {
}

const IntroSection = observer((props:IntroSectionProps) => {
  return (
    <section id="mesh" className="primarysection wf-section">
      <div className="primarycontaint">
        <div className="gridwrap">
          <div className="grid">
            <div id="w-node-edcdb680-57d0-a232-678a-91311c2324e2-2751d89f" className="meshwrap">
              <img
                src="images/IMG_0507.PNG"
                loading="lazy"
                srcSet="images/IMG_0507-p-500.png 500w, images/IMG_0507-p-800.png 800w, images/IMG_0507-p-1080.png 1080w, images/IMG_0507-p-1600.png 1600w, images/IMG_0507-p-2000.png 2000w, images/IMG_0507.PNG 2048w"
                sizes="(max-width: 479px) 92vw, (max-width: 767px) 85vw, (max-width: 991px) 45vw, 47vw"
                alt=""
              />
              <div className="div-block-6">
                <h2 className="heading-7">Mindful Business</h2>
                <p className="meshtext">
                  Holders will be taught a series of cutting-edge wellness and yogic practices that support our mental &amp; emotional state for greater relaxation, creativity, and entrepreneurial or investing success, especially at such a critical juncture in the history of humankind, as we transition from a Web2 to a Web3 world. A healthy and harmonious mind is filled with greater insight and wisdom to make the best decisions even during challenging times filled with Fear, Uncertainty, and Doubt. Moreover, Bebe Chez is known for interlacing his wellness content with current macroeconomics and investing ALPHA to fuse wellness education with better trading or investing practices.
                </p>
              </div>
            </div>
            <div id="w-node-_7c983c98-7a56-4965-b211-5cfcf2cccc56-2751d89f" className="meshwrap">
              <img
                src="images/IMG_0184.PNG"
                loading="lazy"
                srcSet="images/IMG_0184-p-500.png 500w, images/IMG_0184-p-800.png 800w, images/IMG_0184-p-1080.png 1080w, images/IMG_0184-p-1600.png 1600w, images/IMG_0184-p-2000.png 2000w, images/IMG_0184.PNG 2048w"
                sizes="(max-width: 479px) 92vw, (max-width: 767px) 85vw, (max-width: 991px) 45vw, 47vw"
                alt="ape artwork"
              />
              <div className="div-block-6">
                <h2 className="heading-8">Enlightenment</h2>
                <p className="meshtext">
                  Holders will be guided on how to build a roadmap for lifelong success starting with strong ethical pillars to enhance their relationship with the True Self and with the “rest of the world.” To do this requires mastery of the first two pillars or limbs of Yoga (
                  <em>Yama</em>
                  &amp;
                  <em>Niyama</em>
                  ), which will be taught in an integrative way that will allow anyone to put it into practice in their life, as well as master it with time.
                </p>
              </div>
            </div>
            <div id="w-node-_3fd16d67-1a8a-7e9e-5604-12cf585e9313-2751d89f" className="meshwrap">
              <img
                src="images/IMG_0217.PNG"
                loading="lazy"
                srcSet="images/IMG_0217-p-500.png 500w, images/IMG_0217-p-800.png 800w, images/IMG_0217-p-1080.png 1080w, images/IMG_0217-p-1600.png 1600w, images/IMG_0217-p-2000.png 2000w, images/IMG_0217.PNG 2048w"
                sizes="(max-width: 479px) 92vw, (max-width: 767px) 85vw, (max-width: 991px) 45vw, 47vw"
                alt="ape artwork "
              />
              <div className="div-block-6">
                <h2 className="heading-9">Spiritual Mastery</h2>
                <p className="meshtext">
                  Enlightenment is not possible without this next value. Spiritual mastery will be taught in a systematic way for all holders to access at their own leisure. Holders will receive teachings, workshops, courses, and guided practices directly from the Guru himself, Bebe Chez (Dr. Ramos). This is an unbelievable opportunity for anyone to get in at the ground floor for FREE (limit of 111 to start) and either reinforce what they already know or start off fresh. Here, Bebe Chez will teach, in an integrative and highly accessible way, how to master the 4 highest limbs of the Yogic Transpersonal Path, which include:1) Pratyahara or control of the senses &amp; energy, 2) Dharana or concentration, 3) Dhyana or meditation, and lastly 4) Samadhi or Self-Realization.
                </p>
              </div>
            </div>
            <div id="w-node-_13bd17e0-2f27-ccd4-5920-8014cd0692e3-2751d89f" className="meshwrap">
              <img
                src="images/IMG_0203-2.PNG"
                loading="lazy"
                srcSet="images/IMG_0203-2-p-500.png 500w, images/IMG_0203-2-p-800.png 800w, images/IMG_0203-2-p-1080.png 1080w, images/IMG_0203-2-p-1600.png 1600w, images/IMG_0203-2-p-2000.png 2000w, images/IMG_0203-2.PNG 2048w"
                sizes="(max-width: 479px) 92vw, (max-width: 767px) 85vw, (max-width: 991px) 45vw, 47vw"
                alt="ape artwork "
              />
              <div className="div-block-6">
                <h2 className="heading-10">Holistic Wellness</h2>
                <p className="meshtext">
                  Bebe Chez has trained with Sri Dharma Mittra (the modern asana master), is a nutrition and health expert, a stress specialist (American Institute of Stress), and has studied, practiced, and developed many techniques to enhance the functioning of body, mind, and spirit. Holders will again get access to his unique approach to learning with real-life application and integration based on DIRECT EXPERIENCE. Learn how to boost your immune function, fit in more time for your wellbeing as well as for increased productivity, neutralize stress, resist disease, slow down aging, and more.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default IntroSection;
