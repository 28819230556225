import { autorun, makeAutoObservable, reaction } from 'mobx';
// eslint-disable-next-line import/no-unresolved
import type { Web3ReactContextInterface } from '@web3-react/core/dist/types';
import { BigNumber, Contract, ethers } from 'ethers';
import { useEffect } from 'react';
import { NETWORK_CONFIG, NETWORK_ID, WIDTH_SHORTEN_THRESHOLD, WIDTH_THRESHOLD, XS_WIDTH } from '../utils/constants';
import { getTimeLeftObj, tryForever } from '../utils/utils';
import { JSONRpcProviderMaps, getContract } from '../hooks/useContract';

import { ContractCallSettings } from '../types';

import NFT_ABI from '../abi/mint_abi.json';
import RESERVE_NFT_ABI from '../abi/reserve_abi.json';
import { BOGO_WHITELIST, GTD_WHITELIST, REFUND, WAITLIST_WHITELIST } from '../result';

const whitelist = require('../whitelist.json');
const waitlist = require('../waitlist.json');


class Store {
  isIncorrectNetwork = false;

  web3Ctx: Web3ReactContextInterface = null

  totalSupply = 0

  maxMintPerAddress = 0

  userSignature: { dt: string, signature: string } = null

  wlStatus = undefined

  reserveState = 0

  mintPrice = null

  scrollYOffset = 0

  canMint = false

  maxSupply = 0

  countdownToAllowlist = ' '; // non-null empty

  countdownToWaitlist = ' '; // non-null empty

  getConnectedContract(address, ABI) {
    const library = this.web3Ctx?.library;
    const account = this.web3Ctx?.account;
    if (!library || !account) return null;
    try {
      return getContract(address, ABI, library, account);
    } catch (error) {
      console.error('Failed to get contract', error);
    }
    return null;
  }

  getNotConnectedContract(address, ABI, requiredChainID) {
    return new ethers.Contract(address, ABI, JSONRpcProviderMaps[requiredChainID]);
  }

  getContract(address, ABI, requiredChainID = NETWORK_ID) {
    const rpc = this.getNotConnectedContract(address, ABI, requiredChainID);
    const connected = this.getConnectedContract(address, ABI);
    const obj = {
      rpc,
      connected,
      requiredChainID,
    };
    const caller = (method, params = [], settings:ContractCallSettings = {}) => {
      console.log('calling: ', method, params, settings);
      return this.contractCallWrapper(obj, method, params, settings);
    };
    return {...obj, caller};
  }

  async contractCallWrapper(contractObj:{requiredChainID:Number, connected:Contract, rpc:Contract}, method, params = [], settings:ContractCallSettings = {}) {
    let v;
    let c = contractObj.connected || contractObj.rpc;
    if (contractObj.requiredChainID !== this.web3Ctx?.chainId) {
      c = contractObj.rpc;
    }
    if (c) {
      v = await c[method](...params);
      if (!settings.raw && settings.toNumber) {
        v = v.toNumber();
        if (settings.parseInt) {
          v = parseInt(v, 10);
        }
      }
    }
    return v;
  }

  get mintContract() {
    return this.getContract(NETWORK_CONFIG.mintContractAddress, NFT_ABI, NETWORK_ID);
  }

  get reserveContract() {
    return this.getContract(NETWORK_CONFIG.reserveContractAddress, RESERVE_NFT_ABI, NETWORK_ID);
  }

  useInit() {
    return useEffect(() => {
      const cb = () => {
        tryForever(async () => {
          await this.updateSupply();
        }, 'load info');
      };
      cb();
      return reaction(() => this.web3Ctx?.account, cb);
    }, []);
  }

  reservePrice = BigNumber.from('300000000000000000');

  section1Size = {
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight,
  }

  toasts: any[] = []

  screenSize = {
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight,
  }

  setScreenSize(s) {
    this.screenSize = s;
  }

  setScrollYOffset(s) {
    this.scrollYOffset = s;
  }

  get isShortenMode() {
    return this.screenSize.width < WIDTH_SHORTEN_THRESHOLD;
  }

  get isDesktop() {
    return this.screenSize.width > WIDTH_THRESHOLD;
  }

  getSignature(n) {
    const addr = this.web3Ctx?.account.toLowerCase();
    if (n < NETWORK_CONFIG.WAITLIST_PHASE_TS) {
      let signature = GTD_WHITELIST.map[addr];
      if (signature) {
        return {
          type: GTD_WHITELIST.type,
          signature,
        };
      }
      signature = BOGO_WHITELIST.map[addr];
      if (signature) {
        return {
          type: BOGO_WHITELIST.type,
          signature,
        };
      }
    }
    if (n > NETWORK_CONFIG.WAITLIST_PHASE_TS && n < NETWORK_CONFIG.PUBLIC_PHASE_TS) {
      let signature = WAITLIST_WHITELIST.map[addr];
      if (signature) {
        return {
          type: WAITLIST_WHITELIST.type,
          signature,
        };
      }
    }
    if (n > NETWORK_CONFIG.PUBLIC_PHASE_TS) return {};
    return null;
  }

  get isXS() {
    return this.screenSize.width < XS_WIDTH;
  }

  hasReservedArr = []

  get hasReserved() {
    return this.hasReservedArr.find(v => v);
  }

  getErrorMessage(error: any) {
    if (!error) return '';
    let message = error.message;
    const match = message.match(/"message":"([^"]+)"/i);
    if (match) {
      message = match[1];
    }
    if (error.data) {
      return `${message} | ${error.data.message} (code: ${error.data.code})`;
    } if (error.statusCode) {
      return message;
    } if (error.code === 4001) {
      return 'User denied transaction.';
    } if (error.code) {
      return message;
    }
    return `${error}`;
  }

  showErrorToast(error: any) {
    if (!error) return;
    let message = error.message;
    const match = message.match(/"message":"([^"]+)"/i);
    if (match) {
      message = match[1];
    }
    if (error.data) {
      this.showToast({
        title: `Error ${error.code}`,
        description: `${message} | ${error.data.message} (code: ${error.data.code})`,
        type: 'danger',
        ttl: 2000,
      });
    } else if (error.statusCode) {
      this.showToast({
        title: `Error ${error.statusCode} - ${error.error}`,
        description: message,
        type: 'danger',
        ttl: 2000,
      });
    } else if (error.code === 4001) {
      this.showToast({
        title: 'User denied transaction.',
        description: '',
        type: 'danger',
        ttl: 2000,
      });
    } else if (error.code) {
      this.showToast({
        title: `Error ${error.code}`,
        description: message,
        type: 'danger',
        ttl: 2000,
      });
    } else {
      console.log('last');
      this.showToast({
        title: `${error}`,
        type: 'danger',
        ttl: 2000,
      });
    }
  }

  RESERVE_PUBLIC_MAX_PER_USER = 5

  itemsUserPublicReserved = 0

  isUserRefunded = false

  mintedCount = 0

  get refundSignature() {
    return REFUND[this.web3Ctx?.account.toLowerCase()];
  }

  updateSupply = async () => {
    console.log('update supply');
    // this.maxSupply = await this.mintContract.caller('MAX_SUPPLY', []);
    this.totalSupply = await this.mintContract.caller('totalSupply', []);
    this.mintPrice = await this.mintContract.caller('MINT_PRICE', []);
    this.canMint = await this.mintContract.caller('canMint', []);
    this.maxSupply = await this.mintContract.caller('MAX_SUPPLY', []);
    this.reserveState = await this.reserveContract.caller('reserveState', []);
    this.reservePrice = await this.reserveContract.caller('RESERVE_PRICE', []);
    this.RESERVE_PUBLIC_MAX_PER_USER = await this.reserveContract.caller('RESERVE_PUBLIC_MAX_PER_USER', []);
    if (this.web3Ctx?.account) {
      this.hasReservedArr = await this.reserveContract.caller('isReservedAny', [this.web3Ctx?.account]);
      this.itemsUserPublicReserved = await this.reserveContract.caller('itemsUserPublicReserved', [this.web3Ctx?.account]);
      this.isUserRefunded = await this.reserveContract.caller('isUserRefunded', [this.web3Ctx?.account]);
      this.mintedCount = await this.mintContract.caller('balanceOf', [this.web3Ctx?.account]);
    }
  }

  isShowOverlayMenu = false

  logout() {
    localStorage.clear();
    location.reload();
  }

  constructor() {
    makeAutoObservable(this);
  }
}

export const store = new Store();

const interval = setInterval(() => {
  if (Date.now() >= NETWORK_CONFIG.ALLOWLIST_START_TS) {
    store.countdownToAllowlist = null;
    clearInterval(interval);
    return;
  }
  let cndt = getTimeLeftObj(NETWORK_CONFIG.ALLOWLIST_START_TS - Date.now());
  store.countdownToAllowlist = `${cndt.d}:${cndt.h.toString().padStart(2, '0')}:${cndt.m.toString().padStart(2, '0')}:${cndt.s.toString().padStart(2, '0')}`;
}, 500);

const interval2 = setInterval(() => {
  if (store.countdownToAllowlist) {
    return;
  }
  if (Date.now() >= NETWORK_CONFIG.WAITLIST_START_TS) {
    store.countdownToWaitlist = null;
    clearInterval(interval2);
    return;
  }
  let cndt = getTimeLeftObj(NETWORK_CONFIG.WAITLIST_START_TS - Date.now());
  let hours = (cndt.d * 24 + cndt.h);
  store.countdownToWaitlist = `${hours.toString().padStart(2, '0')}:${cndt.m.toString().padStart(2, '0')}:${cndt.s.toString().padStart(2, '0')}`;
}, 500);
