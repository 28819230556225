import React, { Component, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import Reveal from 'react-reveal';
import { useWeb3React } from '@web3-react/core';
import { now } from 'mobx-utils';
import { formatEther, parseEther } from 'ethers/lib/utils';
import { useConnect } from '../../hooks/hooks';
import { store } from '../../mobx/store';
import { getAddressString, getTimeLeftObj, networkSetup, sleep } from '../../utils/utils';
import { GTD_PHASE_NAME, NETWORK_CONFIG, NETWORK_ID } from '../../utils/constants';
import FAQ from '../FAQ';
import IntroSection from '../IntroSection';
import Footer from '../Footer';
import HeaderMenu from '../HeaderMenu';
import OverlayMenu from '../OverlayMenu';
import { Flex, VFlex } from '../Flex';
import Contact from '../Contact';
import Camera from '../Camera';
import { REFUND, WL_MAP, WL_RESULT } from '../../result';
import TimeLeftComponent from '../TimeLeftComponent';
import CurrentPhaseComponent from '../CurrentPhaseComponent';

interface HomePageProps {
}

const HomePage = observer((props: HomePageProps) => {
  const connect = useConnect(true);
  store.useInit();

  const [err, setError] = useState(null); // CLICKED, TX_SENT, TX_CONFIRMED
  const [mintPhase, setMintPhase] = useState('NOTHING'); // CLICKED, TX_SENT, TX_CONFIRMED
  const wlCheckInputRef = useRef();
  const [publicReserveInput, setPublicReserveInput] = useState('');
  const [wlState, setWLState] = useState(false);
  const n = now();
  return (
    <div className="bodywrap">
      <div className="hero-section wf-section">
        <div
          data-animation="default"
          data-collapse="medium"
          data-duration="400"
          data-easing="ease"
          data-easing2="ease"
          role="banner"
          className="navbar-4 w-nav"
        >
          <div className="navwrap">
            <div className="nav-logo-wrap">
              <a
                href="/#"
                className="brand-4 w-nav-brand"
              >
                <img
                  src="/images/Digimonk_Go_Text_File-1.png"
                  loading="lazy"
                  width="243"
                  srcSet="/images/Digimonk_Go_Text_File-1-p-500.png 500w, /images/Digimonk_Go_Text_File-1-p-800.png 800w, /images/Digimonk_Go_Text_File-1.png 932w"
                  sizes="(max-width: 479px) 100vw, 243px"
                  alt="digimonk logo"
                />
              </a>
            </div>
            <HeaderMenu />
          </div>
        </div>
      </div>
      <section
        style={{alignItems: 'center', display: 'flex'}}
        id="About-section"
        data-w-id="5a178d01-a228-507b-0d26-105ca38ead91"
        className="primarysection aboutsection wf-section"
      >
        <div className="customcontainer">
          <div
            className="secondsectioncontainer"
            style={{
              alignItems: 'center',
            }}
          >
            <div className="welcomewrap">
              <h3 className="heading-5">Welcome Degens</h3>
              <h1 className="heading-5 aboutsection">What is DigiMonkz?</h1>
              <div className="meshtext intro">
                Welcome to the home of the next generation of Web3 Entrepreneurs and Digital Gurus. Begin your Pilgrimage right now from the comfort of your own home. Are you a student that is ripe and ready to command your health, life, and finances? Then your teacher awaits you inside the Digital Ashram to break you free from the illusion (
                <em>maya</em>
                ) and awaken you in the HERE &amp; NOW. Let&#x27;s shift your consciousness from the old model based on Newtonian physics of cause and effect into a Quantum model of infinite potential and creativity. Hari Om Tat Sat!
              </div>
              <br />
              <CurrentPhaseComponent />
              {
                (store.hasReserved && n < NETWORK_CONFIG.PUBLIC_PHASE_TS) && (
                  <div
                    className="meshtext intro"
                    style={{fontWeight: 'bold', margin: 12}}
                  >
                    You have reserved your DigiMonkz
                  </div>
                )
              }
              {
                store.itemsUserPublicReserved > 0 && (
                  <div
                    className="meshtext intro"
                    style={{fontWeight: 'bold'}}
                  >
                    {`You have used ${store.itemsUserPublicReserved} of ${store.RESERVE_PUBLIC_MAX_PER_USER} public reserved quotas`}
                  </div>
                )
              }
              {
                (() => {
                  if (!store.web3Ctx?.account) {
                    return (
                      <div
                        className="button mint w-button"
                        onClick={async () => {
                          connect();
                        }}
                      >
                        Connect
                      </div>
                    );
                  }
                  // eslint-disable-next-line eqeqeq
                  if (store.web3Ctx.chainId != NETWORK_ID) {
                    return (
                      <div
                        className="button mint w-button"
                        onClick={async () => {
                          networkSetup();
                        }}
                      >
                        Switch Network
                      </div>
                    );
                  }
                  if (mintPhase === 'CLICKED') {
                    return (
                      <div
                        className="meshtext intro"
                        style={{fontWeight: 'bold'}}
                      >
                        Please confirm the transaction in metamask
                      </div>
                    );
                  }
                  if (mintPhase === 'TX_SENT') {
                    return (
                      <div
                        className="meshtext intro"
                        style={{fontWeight: 'bold'}}
                      >
                        Waiting for transaction to be confirmed
                      </div>
                    );
                  }
                  if (mintPhase === 'TX_CONFIRMED') {
                    return (
                      <div
                        className="meshtext intro"
                        style={{fontWeight: 'bold'}}
                      >
                        Transaction confirmed! Please wait for further announcement about the airdrop!
                      </div>
                    );
                  }
                  if (n > NETWORK_CONFIG.PUBLIC_PHASE_TS) {
                    const parsedInt = parseInt(publicReserveInput, 10);
                    const max = store.maxSupply - store.totalSupply;
                    let refundComponent = null;
                    if (store.reserveState == 2) {
                      if (store.isUserRefunded) {
                        refundComponent = (
                          <div
                            className="meshtext intro"
                          >
                            Refunded
                          </div>
                        );
                      } else if (store.refundSignature) {
                        refundComponent = (
                          <div
                            style={{marginTop: 0}}
                            className="button mint w-button"
                            onClick={async () => {
                              try {
                                setError(null);
                                setMintPhase('CLICKED');
                                await store.reserveContract.connected.estimateGas.refund(...store.refundSignature);
                                const t = await store.reserveContract.connected.refund(...store.refundSignature);
                                setMintPhase('TX_SENT');
                                await t.wait();
                                setMintPhase('NOTHING');
                                await store.updateSupply();
                              } catch (error) {
                                setMintPhase('NOTHING');
                                setError(error);
                              }
                            }}
                          >
                            Refund
                          </div>
                        );
                      }
                      return (
                        <VFlex
                          className="meshtext intro"
                          style={{alignItems: 'center', gap: 12}}
                        >
                          {refundComponent}
                          {/* {
                            err && (
                              <div
                                style={{color: '#FF0000C0'}}
                                className="meshtext intro"

                              >
                                {store.getErrorMessage(err)}
                              </div>
                            )
                          } */}
                          {
                            store.canMint && (
                              <VFlex style={{gap: 12, alignItems: 'center'}}>
                                <h3 className="heading-5" style={{marginBottom: -20}}>MINT</h3>
                                <Flex
                                  style={{alignItems: 'center', gap: 20, marginTop: 18}}
                                >
                                  <input
                                    style={{color: 'black', height: 30}}
                                    type="text"
                                    className="form_field"
                                    maxLength="256"
                                    placeholder={`Max: ${max}`}
                                    id="public_reserve_input"
                                    required=""
                                    onChange={(evt) => {
                                      setPublicReserveInput(evt.target.value);
                                    }}
                                    value={publicReserveInput}
                                  />
                                  {
                                    (isNaN(parsedInt) || parsedInt > max) && publicReserveInput.length > 0 && (
                                      <div
                                        style={{color: '#FF0000C0'}}
                                        className="meshtext intro"

                                      >
                                        Invalid Input
                                      </div>
                                    )
                                  }
                                  {
                                    (!isNaN(parsedInt) || parsedInt <= max) && publicReserveInput.length > 0 && (
                                      <React.Fragment>
                                        <div
                                          style={{marginTop: 0}}
                                          className="button mint w-button"
                                          onClick={async () => {
                                            try {
                                              setError(null);
                                              setMintPhase('CLICKED');
                                              const p = store.mintPrice.mul(parsedInt);
                                              const bal = await store.mintContract.connected.provider.getBalance(store.web3Ctx?.account);
                                              if (bal.lt(p)) {
                                                throw new Error('Insufficient Fund');
                                              }
                                              const gl = await store.mintContract.connected.estimateGas.mint(parsedInt, {
                                                value: p,
                                              });
                                              const t = await store.mintContract.connected.mint(parsedInt, {
                                                value: p,
                                                gasLimit: Math.round(gl.toNumber() * 1.5),
                                              });
                                              setMintPhase('TX_SENT');
                                              await t.wait();
                                              setMintPhase('TX_CONFIRMED');
                                              await store.updateSupply();
                                            } catch (error) {
                                              setMintPhase('NOTHING');
                                              setError(error);
                                            }
                                          }}
                                        >
                                          {`Mint (${formatEther(store.mintPrice.mul(parsedInt))}ETH)`}
                                        </div>
                                      </React.Fragment>
                                    )
                                  }
                                </Flex>
                                {
                                  err && (
                                    <div
                                      style={{color: '#FF0000C0'}}
                                      className="meshtext intro"

                                    >
                                      {store.getErrorMessage(err)}
                                    </div>
                                  )
                                }
                                <div
                                  className="meshtext intro"
                                >
                                  {`You currently owned ${store.mintedCount} Digimonkz`}
                                </div>
                              </VFlex>
                            )
                          }
                        </VFlex>
                      );
                    }
                    // if (max === 0) {
                    //   return (
                    //     <div
                    //       className="meshtext intro"
                    //     >
                    //       Thank you! You have used up all public reserve quotas!
                    //     </div>
                    //   );
                    // }
                    return (
                      <div />
                    );
                  }
                  // const signature = store.getSignature(n);
                  // if (signature) {
                  //   if (n < NETWORK_CONFIG.GTD_PHASE_TS) {
                  //     return (
                  //       <div
                  //         className="meshtext intro"
                  //       >
                  //         {`Please wait for the start of the ${GTD_PHASE_NAME}`}
                  //       </div>
                  //     );
                  //   }

                  //   // if (store.hasReserved) return null;
                  //   return (
                  //     <Flex style={{alignItems: 'center', gap: 10}}>
                  //       <div
                  //         style={{marginTop: 0}}
                  //         className="button mint w-button"
                  //         onClick={async () => {
                  //           try {
                  //             setError(null);
                  //             setMintPhase('CLICKED');
                  //             const bal = await store.reserveContract.connected.provider.getBalance(store.web3Ctx?.account);
                  //             if (bal.lt(store.reservePrice)) {
                  //               throw new Error('Insufficient Fund');
                  //             }
                  //             const gl = await store.reserveContract.connected.estimateGas.reserve(signature.type, signature.signature, {
                  //               value: store.reservePrice,
                  //             });
                  //             const t = await store.reserveContract.connected.reserve(signature.type, signature.signature, {
                  //               value: store.reservePrice,
                  //               gasLimit: Math.round(gl.toNumber() * 1.5),
                  //             });
                  //             setMintPhase('TX_SENT');
                  //             await t.wait();
                  //             setMintPhase('TX_CONFIRMED');
                  //             await store.updateSupply();
                  //           } catch (error) {
                  //             setMintPhase('NOTHING');
                  //             setError(error);
                  //           }
                  //         }}
                  //       >
                  //         {`Reserve (${formatEther(store.reservePrice)}ETH)`}
                  //       </div>
                  //       {
                  //         err && (
                  //           <div
                  //             style={{color: '#FF0000C0'}}
                  //             className="meshtext intro"

                  //           >
                  //             {store.getErrorMessage(err)}
                  //           </div>
                  //         )
                  //       }
                  //     </Flex>
                  //   );
                  // }
                  return (
                    <div
                      className="meshtext intro"
                      style={{fontWeight: 'bold'}}
                    >
                      Sorry you are not in the list for this phase.
                    </div>
                  );
                })()
              }
              <div style={{height: 30}} />
              <TimeLeftComponent />
            </div>
          </div>
        </div>
      </section>
      {/* <VFlex
        style={{
          alignItems: 'center',
          gap: 12,
          marginTop: 30,

        }}
      >
        <h3 className="heading-5" style={{marginBottom: -20}}>WL Check</h3>
        <Flex style={{alignItems: 'center', gap: 20}}>
          <input
            style={{color: 'black', height: 30, marginTop: 18}}
            type="text"
            className="form_field"
            maxLength="256"
            placeholder="0x....."
            id="wl-checker"
            required=""
            ref={wlCheckInputRef}
          />
          <div
            className="button mint w-button"
            onClick={() => {
              setWLState(WL_RESULT[(wlCheckInputRef.current.value || '').toLowerCase().trim()]);
            }}
          >
            Check
          </div>
        </Flex>
        {
          (() => {
            if (wlState === false) return null;
            if (!wlState) {
              return (
                <div>
                  The address is not in WL
                </div>
              );
            }
            const m = wlState.map(s => WL_MAP[s]);
            let str = '';
            m.forEach((s, idx) => {
              str += s;
              if (idx < (m.length - 2)) {
                str += ', ';
              } else if (idx === (m.length - 2)) {
                str += ' and ';
              } else if (idx === (m.length - 1)) {
                str += '.';
              }
            });
            return (
              <div
                className="meshtext intro"
              >
                {`The address is in ${str}`}
              </div>
            );
          })()
        }
      </VFlex> */}
      <Camera />
      <FAQ />
      <IntroSection />
      <Contact />
      <Footer />
      <OverlayMenu />
    </div>
  );
});

export default HomePage;
