import React, {Component, useRef} from 'react';
import {observer} from 'mobx-react';
import { useParallax } from 'react-scroll-parallax';
import { useSize } from '../utils/utils';
import { store } from '../mobx/store';

interface CameraProps {
}

const Camera = observer((props:CameraProps) => {
  const containerRef = useRef();
  const {ref} = useParallax({
    startScroll: containerRef?.current?.offsetTop - (store.isDesktop ? 0 : store.screenSize.height + 200),
    endScroll: containerRef?.current?.offsetTop * 2,
    speed: 0,
    translateX: [0, -170],
  });
  store.scrollYOffset;
  return (
    <section
      ref={containerRef}
      id="About-section"
      data-w-id="053bf870-be75-04c7-3e6c-04e18806a18c"
      className="sticky-section wf-section"
    >
      <div
        className="camera"
      >
        <div
          className="gwn2-image-wrap"
          style={{overflow: 'visible'}}
          ref={ref}
        >
          <div className="gen-2-pfp-wrap">
            <img src="/images/1.png" loading="lazy" sizes="(max-width: 479px) 200.00001525878906px, (max-width: 767px) 400.0000305175781px, (max-width: 991px) 100vw, 1872.0140380859375px" srcSet="images/1.png 500w, images/1.png 800w, images/1.png 1080w, images/1.png 1200w" alt="" className="image-27" />
          </div>
          <div className="gen-2-pfp-wrap">
            <img src="/images/DM-FEMALE-21-1.png" loading="lazy" sizes="(max-width: 479px) 200.00001525878906px, (max-width: 767px) 400.0000305175781px, (max-width: 991px) 100vw, 1872.0140380859375px" srcSet="/images/DM-FEMALE-21-1.png 500w, /images/DM-FEMALE-21-1.png 800w, /images/DM-FEMALE-21-1.png 1080w, /images/DM-FEMALE-21-1.png 1200w" alt="" className="image-27" />
          </div>
          <div className="gen-2-pfp-wrap">
            <img src="/images/3.png" loading="lazy" sizes="(max-width: 479px) 200.00001525878906px, (max-width: 767px) 400.0000305175781px, (max-width: 991px) 100vw, 1872.0140380859375px" srcSet="/images/3.png 500w, /images/3.png 800w, /images/3.png 1080w, /images/3.png 1200w" alt="" className="image-27" />
          </div>
          <div className="gen-2-pfp-wrap">
            <img src="/images/4.png" loading="lazy" sizes="(max-width: 479px) 200.00001525878906px, (max-width: 767px) 400.0000305175781px, (max-width: 991px) 100vw, 1872.0140380859375px" srcSet="/images/4.png 500w, /images/4.png 800w, /images/4.png 1080w, /images/4.png 1200w" alt="" className="image-27" />
          </div>
          <div className="gen-2-pfp-wrap">
            <img src="/images/COMMON_MALE_75.png" loading="lazy" sizes="(max-width: 479px) 200.00001525878906px, (max-width: 767px) 400.0000305175781px, (max-width: 991px) 100vw, 1872.0140380859375px" srcSet="/images/COMMON_MALE_75.png 500w, /images/COMMON_MALE_75.png 800w, /images/COMMON_MALE_75.png 1080w, /images/COMMON_MALE_75.png 1200w" alt="" className="image-27" />
          </div>
        </div>
      </div>
    </section>
  );
});

export default Camera;
