import React, {Component} from 'react';
import {observer} from 'mobx-react';
import { now } from 'mobx-utils';
import { GTD_PHASE_NAME, NETWORK_CONFIG, PUBLIC_PHASE_NAME, WAITLIST_PHASE_NAME } from '../utils/constants';
import { getTimeLeftObj, getTimeLeftSimpleStr } from '../utils/utils';

interface TimeLeftComponentProps {
}

const TimeLeftComponent = observer((props:TimeLeftComponentProps) => {
  const n = now();
  let eventName = null;
  let timeLeftStr = null;
  if (n < NETWORK_CONFIG.GTD_PHASE_TS) {
    eventName = GTD_PHASE_NAME;
    timeLeftStr = getTimeLeftSimpleStr(NETWORK_CONFIG.GTD_PHASE_TS - n);
  } else if (n < NETWORK_CONFIG.WAITLIST_PHASE_TS) {
    eventName = WAITLIST_PHASE_NAME;
    timeLeftStr = getTimeLeftSimpleStr(NETWORK_CONFIG.WAITLIST_PHASE_TS - n);
  } else if (n < NETWORK_CONFIG.PUBLIC_PHASE_TS) {
    eventName = PUBLIC_PHASE_NAME;
    timeLeftStr = getTimeLeftSimpleStr(NETWORK_CONFIG.PUBLIC_PHASE_TS - n);
  }
  if (!timeLeftStr) return null;
  return (
    <div className="meshtext intro">
      {`${eventName} starts in: ${timeLeftStr}`}
    </div>
  );
});

export default TimeLeftComponent;
