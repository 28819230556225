import React from 'react';
import styled from 'styled-components';
import { MediaQueryMobileOnly } from '../utils/constants';

type Props = {

}

export const ResponsiveFlex = styled.div`
display: flex;
align-items: stretch;

${MediaQueryMobileOnly} {
  align-items: center;
  flex-direction: column;
}
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const VFlex = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  ${MediaQueryMobileOnly} {
    align-items: center;
  }
`;

export const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-contents: center;
  align-items: center;
`;
