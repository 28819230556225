import React, {Component} from 'react';
import {observer} from 'mobx-react';
import { store } from '../mobx/store';

interface HeaderMenuProps {
}

const HeaderMenu = observer((props:HeaderMenuProps) => {
  return (
    <div
      className="nav-menu-wrap"
    >
      <nav role="navigation" className="nav-menu-4 w-nav-menu">
        <a href="#About-section" className="navlink w-nav-link">About</a>
        <a href="#faq-section" className="navlink w-nav-link">FAQ</a>
        <a href="#mesh" className="navlink w-nav-link">Mesh</a>
        <a
          href="https://www.youtube.com/@DigiMonkz"
          target="_blank"
          className="navlinkicon w-inline-block"
          rel="noreferrer"
        >
          <img src="/images/whiteyoutubeicon.svg" loading="lazy" alt="Opensea Icon" className="image-25" />
        </a>
        <a
          href="https://opensea.io/collection/digimonkz"
          target="_blank"
          className="navlinkicon w-inline-block"
          rel="noreferrer"
        >
          <img src="/images/WhioteopenseaIcon.svg" loading="lazy" alt="Opensea Icon" className="image-25" />
        </a>
        <a href="https://discord.gg/digimonkz" target="_blank" className="navlinkicon w-inline-block" rel="noreferrer">
          <img
            src="/images/whitediscorditem.svg"
            loading="lazy"
            alt="discord icon."
            className="image-19"
          />
        </a>
        <a href="http://twitter.com/DigiMonkz" target="_blank" className="navlinkicon w-inline-block" rel="noreferrer">
          <img
            src="/images/whitetwittericon.svg"
            loading="lazy"
            alt="twitter icon."
            className="image-20"
          />
        </a>
        <a href="#About-section" className="navlink mint w-nav-link">Mint</a>
      </nav>
      <div className="menu-button-4 w-nav-button">
        <div
          className="w-icon-nav-menu"
          onClick={() => {
            store.isShowOverlayMenu = true;
          }}
        />
      </div>
    </div>
  );
});

export default HeaderMenu;
