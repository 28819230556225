import React, {Component} from 'react';
import {observer} from 'mobx-react';

interface FooterProps {
}

const Footer = observer((props:FooterProps) => {
  return (
    <div className="primarysection footer wf-section">
      <div className="customcontainer">
        <div className="footerwrap">
          <div className="footertop">
            <img
              src="images/Digimonk_Go_Text_File-1.png"
              loading="lazy"
              sizes="(max-width: 479px) 90vw, 400px"
              srcSet="/images/Digimonk_Go_Text_File-1-p-500.png 500w, /images/Digimonk_Go_Text_File-1-p-800.png 800w, /images/Digimonk_Go_Text_File-1.png 932w"
              alt="digimonk logo"
              className="image-5"
            />
          </div>
          <div className="footerbottom">
            <a
              id="opensea"
              href="https://opensea.io/collection/digimonkz"
              target="_blank"
              className="social_link w-inline-block"
              rel="noreferrer"
            >
              <img
                src="images/WhioteopenseaIcon.svg"
                loading="lazy"
                alt="Opensea Icon"
                className="image-24"
              />
            </a>
            <a
              id="Digimonkz-twitter"
              href="http://twitter.com/DigiMonkz"
              target="_blank"
              className="social_link w-inline-block"
              rel="noreferrer"
            >
              <img
                src="images/whitetwittericon.svg"
                loading="lazy"
                alt="twitter icon."
              />
            </a>
            <a id="Discord-link" href="https://discord.gg/digimonkz" target="_blank" className="social_link w-inline-block" rel="noreferrer">
              <img src="/images/whitediscorditem.svg" loading="lazy" alt="discord icon." />
            </a>
            <a id="Discord-link" href="https://www.youtube.com/@DigiMonkz" target="_blank" className="social_link w-inline-block" rel="noreferrer">
              <img src="images/whiteyoutubeicon.svg" loading="lazy" alt="youtube icon." />
            </a>
          </div>
          <div className="emaildiv">
            <div className="text-block-3">Digimonkznft@gmail.com</div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Footer;
