import React, {Component} from 'react';
import {observer} from 'mobx-react';
import { now } from 'mobx-utils';
import { GTD_PHASE_NAME, NETWORK_CONFIG, PUBLIC_PHASE_NAME, WAITLIST_PHASE_NAME } from '../utils/constants';
import { getTimeLeftObj, getTimeLeftSimpleStr } from '../utils/utils';

interface CurrentPhaseComponentProps {
}

const CurrentPhaseComponent = observer((props:CurrentPhaseComponentProps) => {
  const n = now();
  let eventName = null;
  if (n > NETWORK_CONFIG.GTD_PHASE_TS && n < NETWORK_CONFIG.WAITLIST_PHASE_TS) {
    eventName = GTD_PHASE_NAME;
  } else if (n > NETWORK_CONFIG.WAITLIST_PHASE_TS && n < NETWORK_CONFIG.PUBLIC_PHASE_TS) {
    eventName = WAITLIST_PHASE_NAME;
  } else if (n > NETWORK_CONFIG.PUBLIC_PHASE_TS) {
    eventName = PUBLIC_PHASE_NAME;
  }
  if (!eventName) return null;
  return (
    <div
      className="meshtext intro"
      style={{marginBottom: 12}}
    >
      GET YOUR LIFETIME PASS
    </div>
  );
});

export default CurrentPhaseComponent;
