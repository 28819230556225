import React, {Component} from 'react';
import {observer} from 'mobx-react';

interface ContactProps {
}

const Contact = observer((props:ContactProps) => {
  return (
    <React.Fragment>
      <section id="contact" className="primarysection wf-section">
        <div className="customcontainer">
          <div>
            <h1 className="heading-12">Collab Partners </h1>
            <div className="w-layout-grid partnergrid">
              <div id="w-node-_028f898f-661d-f33b-7c8c-fc025428fac1-8c114deb" className="partner-profile-wrap"><img src="images/YGPZ-logo2x.png" loading="lazy" sizes="(max-width: 479px) 83vw, 346.4236145019531px" srcSet="images/YGPZ-logo2x.png 500w, images/YGPZ-logo2x.png 800w, images/YGPZ-logo2x.png 962w" alt="" /></div>
              <div id="w-node-_7b8d29a5-6d49-ab18-9d5d-0bf41d5131c1-8c114deb" className="partner-profile-wrap-2"><img src="images/keungz-logo.png" loading="lazy" id="w-node-_35e1d2c0-c739-23c1-b273-157e3935d4d5-8c114deb" sizes="(max-width: 479px) 92vw, (max-width: 767px) 90vw, 96vw" srcSet="images/keungz-logo.png 500w, images/keungz-logo.png 800w, images/keungz-logo.png 1080w, images/keungz-logo.png 1300w" alt="" /></div>
              <div id="w-node-b569e6ba-28ba-d12e-c17b-7abc26e1273b-8c114deb" className="partner-profile-wrap"><img src="images/image0.png" loading="lazy" id="w-node-_2786809a-ff88-5ee8-04a4-e0707d37716b-8c114deb" sizes="(max-width: 479px) 54vw, 87.30902862548828px" srcSet="images/image0.png 500w, images/image0.png 800w, images/image0.png 1080w, images/image0.png 1600w, images/image0.png 1830w" alt="" /></div>
              <div id="w-node-ebde141d-6d45-48b6-9fd6-55369d0b6292-8c114deb" className="partner-profile-wrap"><img src="images/image.png" loading="lazy" id="w-node-_358af985-67ce-d265-4f18-cb7d3d590569-8c114deb" sizes="(max-width: 479px) 83vw, 335.9895935058594px" srcSet="images/image.png 500w, images/image.png 800w, images/image.png 1080w, images/image.png 1500w" alt="" /></div>
              <div id="w-node-aafda6a7-d51f-fed5-1bc8-c9c3b1ead48f-8c114deb" className="partner-profile-wrap"><img src="images/image-2.png" loading="lazy" id="w-node-_8505b98c-11ec-623e-f6f3-de6bc6c9ed1c-8c114deb" sizes="(max-width: 479px) 54vw, 111.99653625488281px" srcSet="images/image-2.png 500w, images/image-2.png 800w, images/image-2.png 1080w, images/image-2.png 1510w" alt="" /></div>
              <div id="w-node-d690fde2-e3d1-8bbe-94d5-c09d56229fac-8c114deb" className="partner-profile-wrap"><img src="images/image-1.png" loading="lazy" sizes="(max-width: 479px) 83vw, 335.9895935058594px" srcSet="images/image-1.png 500w, images/image-1.png 800w, images/image-1.png 1080w, images/image-1.png 1500w" alt="" /></div>
              <div id="w-node-_5ee964c0-2b94-684e-09a8-42621114be2a-8c114deb" className="partner-profile-wrap"><img src="images/Logo.png" loading="lazy" sizes="(max-width: 479px) 83vw, 277.5694580078125px" srcSet="images/Logo.png 500w, images/Logo.png 800w, images/Logo.png 1080w, images/Logo.png 1600w, images/Logo.png 2000w, images/Logo.png 2600w, images/Logo.png 3200w, images/Logo.png 4191w" alt="" /></div>
              <div id="w-node-_43d51f3f-4dea-e691-68c2-d679d5a10367-8c114deb" className="partner-profile-wrap"><img src="images/Logo.svg" loading="lazy" alt="" /></div>
              <div id="w-node-_1e08bb65-2393-a0b5-b1b5-25a459a6d6aa-8c114deb" className="partner-profile-wrap"><img src="images/INPEAK-LOGO.png" loading="lazy" alt="" /></div>
            </div>
          </div>
        </div>
      </section>
      <section id="contact" className="primarysection wf-section">
        <div className="customcontainer">
          <div>
            <h1 className="heading-12">Dream Team </h1>
            <div className="w-layout-grid grid-2">
              <div id="w-node-a895b55e-c191-053a-0d95-f81801d442d2-8c114deb" className="team-profile-wrap">
                <div className="pfp is-small mobile" />
                <div>
                  <h4 className="heading-13">BebeChez - Founder</h4>
                </div>
              </div>
              <div id="w-node-a895b55e-c191-053a-0d95-f81801d442d7-8c114deb" className="team-profile-wrap">
                <div className="pfp2 is-small mobile" />
                <div>
                  <h4 className="heading-13">Keungz - Dev/Advisor </h4>
                </div>
              </div>
              <div id="w-node-a895b55e-c191-053a-0d95-f81801d442dc-8c114deb" className="team-profile-wrap">
                <div className="pfp3 is-small mobile" />
                <div>
                  <h4 className="heading-13">PunkPoser - Art Director</h4>
                </div>
              </div>
              <div id="w-node-a895b55e-c191-053a-0d95-f81801d442e1-8c114deb" className="team-profile-wrap">
                <div className="pfp4 mobile" />
                <div>
                  <h4 className="heading-13">HypedBodega -  Collab Manager</h4>
                </div>
              </div>
              <div id="w-node-_11b8c085-5a8d-6411-d1e2-4728d128901e-8c114deb" className="team-profile-wrap">
                <div className="pfp5" />
                <div>
                  <h4 className="heading-13">Kakarot -  Advisor</h4>
                </div>
              </div>
              <div id="w-node-_8ba2b3f9-fd26-e711-1434-60eb3535e02a-8c114deb" className="team-profile-wrap">
                <div className="pfp6" />
                <div>
                  <h4 className="heading-13">Blockchainz -  Community Manager</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
});

export default Contact;
