import React, {Component, useState } from 'react';
import {observer} from 'mobx-react';


interface FAQProps {
}

const FAQItem = observer(({title, content, mh}) => {
  const [isShowing, setIsShowing] = useState();
  return (
    <div className="accordian-item">
      <div data-w-id="29e09b3c-db10-6e46-ad30-470e6e962c32" className="accordian-item-trigger">
        <h2 className="accordiantrigger" onClick={() => setIsShowing(!isShowing)}>
          {title}
        </h2>
      </div>
      <div style={{maxHeight: isShowing ? (mh || '800px') : '0px', transition: 'all 0.5s ease-out'}} className="accordian-content">
        <p className="accordian-contetn">
          {content}
        </p>
      </div>
    </div>
  );
});

const FAQ = observer((props:FAQProps) => {
  return (
    <div id="faq-section" className="primarysection wf-section">
      <div className="customcontainer">
        <div data-w-id="e6f8ef5d-e408-e188-a4d6-18eb82b808cf" className="faqdiv">
          <img
            src="images/ledger.png"
            loading="lazy"
            width="91"
            alt="cartoon usb."
            className="image-9"
          />
          <h1 className="heading-3">FAQ</h1>
          <img
            src="images/ohm.png"
            loading="lazy"
            width="84"
            alt="cartoon ohm symbol."
            className="image-10"
          />
          <div className="faq-grid">
            <FAQItem
              title="What is the mint price per Digimonk?"
              content={(
                <p className="accordian-contetn">$495 fixed USD price paid as a deposit in ETH with airdrop sometime soon after we reach the maximum number of deposits.</p>
              )}
            />
            <FAQItem
              title="How many Digimonks are there?"
              content={(
                <p className="accordian-contetn">
                  Genesis 111 on
                  {' '}
                  <a href="https://opensea.io/collection/digimonkz" target="_blank" className="link" rel="noreferrer">OpenSea</a>
                  , Upcoming Gen 2 Collection is of 1,111 NFTs
                  <br />
                  12 (Animated Guru/Legendary)
                  <br />
                  31 Ultra Rare Astral
                  <br />
                  69 Super Rare Undead
                  <br />
                  110 Rare Female
                  <br />
                  889 Common Male
                </p>
              )}
            />
            <FAQItem
              title="Is there a Roadmap?"
              content={(
                <React.Fragment>
                  COMING SOON. Please check out our LITE PAPER here -
                  {' '}
                  <a
                    href="http://medium.com/@digimonkz/digimonkz-nft-starts-now-the-lite-paper-38b0334229d3"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <strong><em className="italic-text">Medium </em></strong>
                  </a>
                </React.Fragment>
              )}
            />
            <FAQItem
              title="What can I do with my Digimonk?"
              content="Your DigiMonkz is your ticket into the Life of a DEGEN Journey of lifelong
              learning, collaborating, and building alongside a living Web3 Wellness Master. Your NFT earns you the
              role of sadhu, with the hopes that, through your own efforts, you reach the Guru level."
            />
            <FAQItem
              title="Who is BebeChez?"
              content="Dr. Brian P. Ramos is a Yale University-trained Neuroscientist and Master
              Yogi with decades of experience teaching people how to live more stress and disease-free, reach
              self-mastery and peak performance, enhance creativity, boost immune function, and more. He is the
              author of &quot;The Art of Stress-Free Living&quot; &amp; &quot;Voyage from Human to God.&quot; He is
              the creator of DNA Hacker Secrets Mastermind, the host of the “Shattering the Taboo” Podcast, and the
              founder of DigiMonkz."
            />
            <FAQItem
              title="Wen is Mint?"
              content={(
                <p className="accordian-contetn">Legendary Auction on OpenSea to kick things off. Will use a deposit/airdrop mint style and will begin taking Guaranteed List deposits soon after the auction starts or right after it ends.</p>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default FAQ;
