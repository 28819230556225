import moment from 'moment';

import styled, { keyframes } from 'styled-components';


export const NetworkContextName = 'NETWORK';

export const NETWORK_ID = parseInt(process.env.REACT_APP_NETWORK, 10) || '80001';

export const CONNECTED_BEFORE_KEY = 'amsdfbawmfeawefaeagewg';

// const BASE_URL = ;
// const BASE_URL = 'http://localhost:31381';


export const MS = 1;
export const S_MS = MS * 1000;
export const MINUTE_MS = S_MS * 60;
export const HOUR_MS = MINUTE_MS * 60;
export const DAY_MS = HOUR_MS * 24;
export const WEEK_MS = DAY_MS * 7;

const GTD_PHASE_TS = 1682989200000;

export const NETWORK_CONFIGS = {
  1: {
    chinaID: 1,
    rpc: 'https://rpc.ankr.com/eth',
    id: '0x1',
    chainName: 'ETH Mainnet',
    mintContractAddress: '0x3067599C11118D2D4C708D119820775425AF029c',
    reserveContractAddress: '0xeF06d51523Da2fA5DEfd598eCB281c8505d89243',
    GTD_PHASE_TS,
    WAITLIST_PHASE_TS: GTD_PHASE_TS + DAY_MS * 2,
    PUBLIC_PHASE_TS: GTD_PHASE_TS + DAY_MS * 5,
  },
  80001: {
    rpc: 'https://rpc.ankr.com/polygon_mumbai',
    id: '0x13881',
    chainName: 'Mumbai Test Network',
    chainData: {
      chainId: '0x13881',
      chainName: 'Mumbai',
      nativeCurrency: {
        name: 'MATIC',
        symbol: 'MATIC',
        decimals: 18,
      },
      rpcUrls: [
        'https://matic-mumbai.chainstacklabs.com',
        'https://rpc-mumbai.maticvigil.com',
        'https://matic-testnet-archive-rpc.bwarelabs.com',
      ],
      blockExplorerUrls: [
        'https://mumbai.polygonscan.com',
      ],
    },
    mintContractAddress: '0xbE0BDfC894C80e121960fE9B568F8EDBc247EC81',
    reserveContractAddress: '0x8336AEc05f27703E37ea7AE4E0081277B8722abe',
    GTD_PHASE_TS: Date.now() + S_MS * 1,
    WAITLIST_PHASE_TS: Date.now() + S_MS * 5,
    PUBLIC_PHASE_TS: Date.now() + MINUTE_MS * 2,
  },
};

type ConfigType = typeof NETWORK_CONFIGS[1];

export const WIDTH_SHORTEN_THRESHOLD = 1024;
export const WIDTH_THRESHOLD = 768;
export const MAX_DESKTOP_WIDTH = 1100;

export const NETWORK_CONFIG:ConfigType = NETWORK_CONFIGS[NETWORK_ID];

export const COLORS = {
  grey1: '#3A4142',
  grey2: '#2E3435',
  grey3: '#1A1E1F',
  dark: '#0E0C34',
  purple1: '#D585FF',
  purple2: '#BF77E6',
  purple3: '#9F63BF',
  skyblue: '#64CCF7',
  blue1: '#a4d9f6',
  blue2: '#80c9f2',
  blue3: '#69b7e3',
  green1: '#8BFCE5',
  green2: '#7DE3CF',
  green3: '#68BDAC',
  pink: '#FCC5B1',
  orange: '#FCD4A4',
  alert: '#DE3146',
  darkBlack: '#050505',

  main: 'white',
  highlight: 'rgb(215, 240, 211)',
  highlight2: 'rgb(239, 182, 21)',
  secondary: '#b5b8fc',
};

export const FONT = {
  CaliforniaGothic: 'CaliforniaGothic',
  Openhuninn: 'Openhuninn',
  Minercraftory: 'Minercraftory',
};

export const getLtMediaQuery = size => `@media only screen and (max-width: ${size}px)`;


export const XS_WIDTH = 600;
export const SM_WIDTH = 960;
export const MD_WIDTH = 1280;
export const LG_WIDTH = 1920;

export const MediaQueryMobileOnly = getLtMediaQuery(WIDTH_THRESHOLD);
export const MediaQueryShortenOnly = getLtMediaQuery(WIDTH_SHORTEN_THRESHOLD + 1);

export const MediaQueryXSOnly = getLtMediaQuery(XS_WIDTH);
export const MediaQuerySMOnly = getLtMediaQuery(SM_WIDTH);
export const MediaQueryMDOnly = getLtMediaQuery(MD_WIDTH);
export const MediaQueryLGOnly = getLtMediaQuery(LG_WIDTH);


const presaleMoment = moment(NETWORK_CONFIG.ALLOWLIST_START_TS);
// const publicSaleMoment = moment(NETWORK_CONFIG.PUBLIC_SALE_TS);

export const STRING = {
  // presaleNotStartedAlert: `Presale has not started yet. Presale will be started at ${presaleMoment.format('Do MMM hh:mma UTC+z')}.`,
  // publicSaleNotStartedAlert: `Public sale has not started yet. Public sale will be started at ${publicSaleMoment.format('Do MMM hh:mma UTC+z')}.`,
  // selectMintCount: 'Please select count of you want to mint',
  // verifyWhitelistMessage: 'This will confirm and verify if you can mint during presale.',
  // insufficientFunds: 'Not enough ETH to mint',
  // moreThanMaxMint: 'You would like to mint {0} , but only {1} is available for you to mint now.',
  // confirmTx: 'Please confirm the transaction at your wallet',
  // txSubmitted: 'Transaction submitted, waiting for confirmation',
  // presaleNotStartedAlert: `Presale has not started yet. Please come back at ${presaleMoment.format('hh:mma UTC+z on Do MMM')}.`,
};

export const PHASE = {
  BEFORE_ANYTHING: 'BEFORE_ANYTHING',
  PRESALE: 'PRESALE',
  INTER_PHASE: 'INTER_PHASE',
  PUBLIC: 'PUBLIC',
};

export const ROTATE_KEYFRAMES = keyframes`
0% { transform: rotate(0deg); }
50% { transform: rotate(180deg);  }
100% { transform: rotate(360deg);  }
`;

export const CONTAINER_MAX_WIDTH = 1200;

export const GTD_PHASE_NAME = 'GTD and Bogo whitelist reserve phase';
export const WAITLIST_PHASE_NAME = 'Waitlist reserve phase';
export const PUBLIC_PHASE_NAME = 'Public reserve phase';
