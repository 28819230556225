import React, { useEffect } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';

import Providers from './components/Providers';
import GlobalCSS from './GlobalStyle';
import { store } from './mobx/store';
import HomePage from './components/pages/HomePage';

const Main = () => {
  const ctx = useWeb3React();

  useEffect(() => {
    store.web3Ctx = ctx;
    if (ctx.account) {
      document.body.className = 'show-capsule';
    }
  }, [ctx, ctx?.account]);

  // const mintContract = useMintContract();
  // useEffect(() => {
  //   store.mintContract = mintContract;
  //   // store.mintContract = new MockMintContract(); MOCK
  //   store.updateSupply();
  // }, [mintContract]);

  useEffect(() => {
    const listener = () => {
      // if (store.audioRef) {
      //   store.audioRef.play();
      // }
      requestAnimationFrame(() => {
        store.setScrollYOffset(window.pageYOffset);
      });
    };
    window.addEventListener('scroll', listener);
    const resizeListener = () => {
      requestAnimationFrame(() => {
        store.setScreenSize({
          width: document.documentElement.clientWidth,
          height: document.documentElement.clientHeight,
        });
      });
    };
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('scroll', listener);
      window.removeEventListener('resize', resizeListener);
    };
  }, []);
  return (
    <HomePage />
  );
};

const App = () => {
  return (
    <Providers>
      <GlobalCSS />
      <div className="background" />
      <BrowserRouter>
        <Route component={Main} />
      </BrowserRouter>
    </Providers>
  );
};

export default App;
