import React, {Component} from 'react';
import {observer} from 'mobx-react';
import { VFlex } from './Flex';
import { store } from '../mobx/store';

interface OverlayMenuProps {
}

const OverlayMenu = observer((props:OverlayMenuProps) => {
  return (
    <VFlex
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'linear-gradient(180deg, #1a1e1fFF 0%, #1a1e1f00 100%)',
        paddingTop: 90,
        pointerEvents: store.isShowOverlayMenu ? 'all' : 'none',
        opacity: store.isShowOverlayMenu ? 1 : 0,
        transition: 'all 0.3s',
        alignItems: 'center',
      }}
      onClick={() => {
        store.isShowOverlayMenu = false;
      }}
    >
      <a href="#About-section" className="navlink w-nav-link">About</a>
      <a href="#faq-section" className="navlink w-nav-link">FAQ</a>
      <a href="#mesh" className="navlink w-nav-link">Mesh</a>
      <a href="https://www.youtube.com/@DigiMonkz" target="_blank" className="navlinkicon w-inline-block" rel="noreferrer"><img src="images/whiteyoutubeicon.svg" loading="lazy" alt="youtube icon." className="image-25" /></a>
      <a
        href="https://opensea.io/collection/digimonkz"
        target="_blank"
        className="navlinkicon w-inline-block"
        rel="noreferrer"
      >
        <img src="/images/WhioteopenseaIcon.svg" loading="lazy" alt="Opensea Icon" className="image-25" />
      </a>
      <a href="http://discord.gg/t2xeQnY6" target="_blank" className="navlinkicon w-inline-block" rel="noreferrer">
        <img
          src="/images/whitediscorditem.svg"
          loading="lazy"
          alt="discord icon."
          className="image-19"
        />
      </a>
      <a href="http://twitter.com/DigiMonkz" target="_blank" className="navlinkicon w-inline-block" rel="noreferrer">
        <img
          src="/images/whitetwittericon.svg"
          loading="lazy"
          alt="twitter icon."
          className="image-20"
        />
      </a>
      <a href="#About-section" className="navlink mint w-nav-link">
        Mint
      </a>
    </VFlex>
  );
});

export default OverlayMenu;
